<template>
  <v-container
    class="px-lg-10 mx-0 background-color: white"
    style="border: 1px solid grey"
  >
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="10"
      >
        <v-spacer />
      </v-col>
      <v-col
        class="text-right"
        cols="12"
        sm="6"
        md="2"
      >
        <v-btn
          color="orange"
          dark
          class="mb-2"
          @click="cancelButtonClick"
        >
          KEMBALI
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Nama Penyewa
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        {{ fullName }}
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Nama Perusahaan
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        {{ dataCustomer.company_name }}
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Telepon
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        {{ dataCustomer.phone }}
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Email
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        {{ dataCustomer.email }}
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Alamat
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        {{ dataCustomer.address_string }}
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Kode Pos
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        {{ dataCustomer.poscode }}
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Status
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        <v-select
          :value="selectedStatus"
          class="mx-0 my-0 px-0 py-0"
          :items="statusItems"
          item-text="text"
          item-value="value"
          @change="handleCustomerStatus"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Langganan Newsletter
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        <v-checkbox
          v-model="dataCustomer.newsletter_subscribe"
          class="mx-0 my-0 px-0 py-0"
          :label="newsLetterSubcriptionStatus"
          @click="handleNewsletterSubscription"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      dataCustomer: {
        hash: '',
        avatar: '',
        fname: '',
        lname: '',
        phone: '',
        email: '',
        email_verified: '',
        profile_verified: '',
        profile_verified_string: '',
        company_name: '',
        wpoint_status: '',
        ktp_verified: '',
        ktp_num: '',
        ktp_img_path: '',
        ktp_selfie_img_path: '',
        address: '',
        address_string: '',
        poscode: '',
        status: '1',
        status_string: '',
        newsletter_subscribe: '1',
      },
      statusItems: [
        { text: 'Aktif', value: 1 },
        { text: 'Non-Aktif', value: 2 },
        { text: 'Blokir', value: 3 },
      ],
    }),

    computed: {
      fullName () {
        return this.dataCustomer.fname + ' ' + this.dataCustomer.lname
      },
      selectedStatus () {
        return parseInt(this.dataCustomer.status)
      },
      newsLetterSubcriptionStatus () {
        let status
        this.dataCustomer.newsletter_subscribe ? status = 'Aktif' : status = 'Non-Aktif'
        return status
      },
    },

    watch: {
    },

    created () {
      this.initialize(this.$route.params.id)
    },

    methods: {
      initialize (id) {
        const vm = this

        const requestBody = {
          cust_hash: id,
        }

        axios.post('/t/customer/detail', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.dataCustomer = res.data.data.detail
          }
        })
      },

      handleCustomerStatus (val) {
        const requestBody = {
          cust_hash: this.dataCustomer.hash,
          new_status: val,
        }

        axios.post('/t/customer/mod/status', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.data.message)
          }
        }).catch((e) => {
          e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
        })
      },

      handleNewsletterSubscription () {
        const requestBody = {
          cust_hash: this.dataCustomer.hash,
        }

        axios.post('/t/customer/mod/newsletterSub', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.data.message)
          }
        }).catch((e) => {
          e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
        })
      },

      cancelButtonClick () {
        this.$router.push({ name: 'Customer' })
      },
    },
  }
</script>
